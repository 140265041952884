import { Banner, CTA, HorizontalWordLoop } from '@/components'
import image from '../../assets/images/services/web.png'
import animation from '../../assets/animations/MobileBrowser.json'
import Lottie from 'react-lottie';
import { Fade, JackInTheBox } from "react-awesome-reveal";

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animation,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};

export const WebDevelopment = () => {

    return <>
        <JackInTheBox triggerOnce={true} delay={1000}>
            <Banner
                subtitle={"Software Development"}
                title={' <span class="stroke">INNOVATIVE </span> <br />Web Development'}
                image={image}
                description="Accelerate your business growth with custom web applications designed to drive innovation."
            />
        </JackInTheBox>
        <Fade triggerOnce={true} delay={100}>
            <HorizontalWordLoop title={"WEB DEVELOPMENT"} />
        </Fade>

        <div className="bg-dark1">
            <div className="container">
            <div className="row py-5 align-items-center justify-content-center justify-content-lg-start ">
            <div className="col-lg-4 col-8">
                        <Fade triggerOnce={true}>
                            <Lottie options={defaultOptions}
                                height={'100%'}
                                width={'100%'}
                            />
                        </Fade>
                    </div>
                    <div className="col-lg-8 mt-lg-0 mt-3">
                        <Fade triggerOnce={true}>
                            <h3 className='text-primary'>
                                <span className="stroke primary">Your</span> customers will not give you a second chance to make a <span className="stroke primary">first impression</span></h3>
                        </Fade>

                        <Fade triggerOnce={true}>
                            <p className="text-white mt-3 ">
                                A web development company’s mission is to craft solutions that align with each client’s unique business goals. By leveraging expert web development services, you can build a high-performing product from the ground up or enhance an existing one, adapting seamlessly to evolving user demands and market trends.
                            </p>
                            <p className="text-white mt-3 ">
                                We have experience in all facets of web development to help our clients reach their full potential. Put your business online, earn more sales and discover more leads.
                            </p>
                        </Fade>
                    </div>
                </div>
            </div>
        </div>
        <CTA
            title={"Get Your <br><span class='stroke'> First Impression</span>"}
        />

    </>

}