import { Banner } from '@/components'
import mailer from '../assets/images/contact.png'
import { Fade, Flip, Zoom, Roll, JackInTheBox, Slide } from "react-awesome-reveal";


export const Contact = () => {
    return (
        <>
            <JackInTheBox triggerOnce={true} delay={1000}>
                <Banner
                    subtitle={"Contact us"}
                    title={'CONNECT FOR  <br /><span class="stroke">INNOVATIVE </span>SOLUTIONS'}
                    image={mailer}
                    description="Connect with us to unlock innovative software solutions tailored to your business needs. Let's shape the future of technology together!"
                />
            </JackInTheBox>
            <div className=" contact-crev ">
                <div className="py-100 container mh-100v align-items-center d-flex ">
                    <div className="row align-items-center">
                        <div className="col-12">
                            <Slide triggerOnce={true}>
                                <h6 className="circle-label text-white">
                                    <span>GET IN TOUCH</span>
                                </h6>
                            </Slide>
                            <Slide triggerOnce={true} delay={300}>
                                <h2 className="fs-50 text-white mt-3">Building custom software</h2>
                            </Slide>
                            <Slide triggerOnce={true} delay={500}>
                                <h2 className="fs-50 text-white ">doesn't need to be hard.</h2>
                            </Slide>

                        </div>
                        <div className="col-lg-6">
                            <Fade triggerOnce={true}>  <p className='text-white fs-16 mt-3'>Are you looking to:</p></Fade>
                            <Fade triggerOnce={true}>
                                <ul className='list'>
                                    <li><p className='text-white fs-16 '>Release a new consumer web or mobile service?</p></li>
                                    <li><p className='text-white fs-16 '>Help your employees do their jobs more efficiently with software?</p></li>
                                    <li><p className='text-white fs-16 '>Replace an outdated system?</p></li>
                                    <li><p className='text-white fs-16 '>Enhance an existing software application?</p></li>
                                    <li><p className='text-white fs-16 '>Finish a software project and need help?</p></li>
                                </ul>
                            </Fade>
                            <Fade triggerOnce={true}>
                                <p className='text-white fs-16 mt-3'>We provide solutions for all of these challenges and more. Our customer-centric approach emphasizes delivering the right solution with the right technologies, based on your specific needs.</p>
                            </Fade>

                            {/* <h2 className="fs-50 text-white mt-3">Don't Hesitate  <br />To Reach Out </h2>
                            <p className='text-white fs-16 mt-3'>If you would like to work with us or just want to get in touch, we’d love to hear from you!</p> */}
                            <div className='py-4'>
                                <Fade triggerOnce={true}>
                                    <div className="d-flex align-items-center  mt-2">
                                        <a className="theme-btn btn-outline white circular-social-icon mr-md-3 mr-2" target='_blank'>
                                            <i className="fa-solid fa-location-dot"></i>
                                        </a>
                                        <div className=' text-white fs-16'>Beirut, Lebanon</div>
                                    </div>
                                </Fade>
                                <Fade triggerOnce={true} delay={200}>
                                    <div className="d-flex align-items-center mt-2 ">
                                        <a className="theme-btn btn-outline white circular-social-icon mr-md-3 mr-2" target='_blank'>
                                            <i className="fa-solid fa-phone fs-16"></i>
                                        </a>
                                        <a href="" target='_blank' className=' text-white fs-16'>+961 03 840 905</a>
                                    </div>
                                </Fade>
                                <Fade triggerOnce={true} delay={400}>
                                    <div className="d-flex align-items-center mt-2 ">
                                        <a className="theme-btn btn-outline white circular-social-icon mr-md-3 mr-2" target='_blank'>
                                            <i className="fa-regular fa-envelope"></i>
                                        </a>
                                        <a href="" target='_blank' className=' text-white fs-16 '>info@darperso.com</a>
                                    </div>
                                </Fade>
                            </div>

                            <h5 className='text-white  fw-700 mt-2'>Follow us</h5>
                            <div className="d-flex align-items-center mt-2 flex-wrap ">
                                <Fade triggerOnce={true} delay={200}>
                                    <a className="theme-btn btn-outline white circular-social-icon  mt-2 mr-2" target='_blank'>
                                        <i className="fa-brands fa-facebook-f"></i>
                                    </a>
                                </Fade>
                                <Fade triggerOnce={true} delay={300}>
                                    <a className="theme-btn btn-outline white circular-social-icon  mt-2 mr-2" target='_blank'>
                                        <i className="fa-brands fa-instagram"></i>
                                    </a>
                                </Fade>
                                <Fade triggerOnce={true} delay={400}>
                                    <a className="theme-btn btn-outline white circular-social-icon  mt-2 mr-2" target='_blank'>
                                        <i className="fa-brands fa-x-twitter"></i>
                                    </a>
                                </Fade>
                                <Fade triggerOnce={true} delay={500}>
                                    <a className="theme-btn btn-outline white circular-social-icon  mt-2 mr-2" target='_blank'>
                                        <i className="fa-brands fa-tiktok"></i>
                                    </a>
                                </Fade>
                                <Fade triggerOnce={true} delay={600}>
                                    <a className="theme-btn btn-outline white circular-social-icon  mt-2 mr-2" target='_blank'>
                                        <i className="fa-brands fa-linkedin-in"></i>
                                    </a>
                                </Fade>
                            </div>
                        </div>

                        <div className="col-lg-6 mt-lg-0 mt-3">
                            <form action="
                        ">
                                <div className="row">
                                    <div className="col-lg-6  mt-3">
                                        <Fade triggerOnce={true}>
                                            <input type="text" name='name' placeholder='Name' />
                                        </Fade>
                                    </div>
                                    <div className="col-lg-6  mt-3">
                                        <Fade triggerOnce={true} delay={200}>
                                            <input type="text" name='name' placeholder='Email' />
                                        </Fade>
                                    </div>
                                    <div className="col-lg-12 mt-3">
                                    <Fade triggerOnce={true} delay={300}>
                                        <input type="text" name='name' placeholder='Subject' />
                                        </Fade>

                                    </div>

                                    <div className="col-lg-12 mt-3">
                                    <Fade triggerOnce={true} delay={400}>
                                        <textarea name='name' placeholder='Message' />
                                        </Fade>
                                    </div>

                                    <div className="pl-3 mt-3">
                                    <Fade triggerOnce={true} delay={600}>
                                        <a className=" theme-btn btn-outline circular white w-auto d-flex" href="#">
                                            <span className='fs-16'>Let's Talk</span>
                                        </a>
                                        </Fade>
                                    </div>
                                </div>


                            </form>
                        </div>
                    </div>
                </div>

            </div >
        </>
    )
}