import { Link } from 'react-router-dom'
import image from '../assets/images/eagle.png'
import { ROUTES } from '@/config/types'
import { handlePageChange } from './Navigation'
import { Fade, Zoom } from 'react-awesome-reveal'

export const CTA = ({ title, description = undefined }) => {
    return <Zoom triggerOnce={true} direction='up'>
        <div className=" banner  h-auto no-parallalex" style={{ backgroundImage: `url(${image})` }}>
            <div className="overlay black"></div>

            <div className="container py-80 d-flex   ">
                <div className="row justify-content-center align-items-center py-lg-3">
                    <div className="col-xl-8">
                        <Fade triggerOnce={true} delay={1000}>
                            <h2 className='text-white-always text-center'
                                dangerouslySetInnerHTML={{ __html: title }}
                            />
                        </Fade>
                        <Fade triggerOnce={true} delay={1200}>
                            {description && <p className='text-white-always text-center mt-3 ' dangerouslySetInnerHTML={{ __html: description }} />}
                        </Fade>
                        <div className="d-flex justify-content-center mt-3">
                        <Fade triggerOnce={true} delay={1400}>
                          <Link className=" theme-btn btn-filled w-auto" to={ROUTES.CONTACT} onClick={handlePageChange}>
                                <span>Free Consultation</span>
                            </Link>
                          </Fade>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Zoom>
}