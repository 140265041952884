import { Slide } from "react-awesome-reveal";
import Collapsible from "react-collapsible";

type Props = {
  title: string;
  className?: string;
};

type AccordionTitleProps = {
  title: string;
};

const AccordionTitle = ({ title }: AccordionTitleProps) => {
  
  return (
    <div className="d-flex justify-content-between accordion-title-container">
      <h5 className="accordion-title py-3">{title}</h5>
      <div className="py-3">
      <i className="fa-solid fa-circle-chevron-down" />
      </div>
    </div>
  );
};
export const Accordion = ({ title, classParentString=undefined, children,delay=0 }) => {
  return (
   <Slide direction="up" triggerOnce delay={delay}>
     <Collapsible
      trigger={<AccordionTitle title={title} />}
      classParentString={classParentString ?? "accordion-container"}
    >
    <div dangerouslySetInnerHTML={{__html:children}}></div>
    </Collapsible>
   </Slide>
  );
}
