import { Zoom } from 'react-awesome-reveal';
import Lottie from 'react-lottie';
const defaultOptions = {
    loop: true,
    autoplay: true,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};
export const CardItem = ({ icon = undefined, title, text, onClick = undefined, lottie = undefined, delay = 0 }) => {

    return (
        <Zoom delay={delay}
            triggerOnce={true}
            className='h-100'
        >
            <div className={`card-item-box h-100 ${onClick ? ' pointer ' : ''}`} onClick={onClick}>
                {lottie ?
                    <div className='lottie-wrapper' style={{ width: 100 }}>
                        <Lottie options={{ ...defaultOptions, animationData: lottie }}
                            height={100}
                            width={100}
                            style={{ textAlign: 'left' }}

                        />
                    </div> : <i className={`${icon} text-primary mb-3`}></i>

                }

                <h5 className='value-title mt-4 text-white pb-2 '>{title}</h5>
                <div className="line my-3"></div>
                <p className='value-text text-white fs-16 pt-2'>{text}</p>
            </div>
        </Zoom>
    )
}