import { ROUTES } from "@/config/types"
import { Link } from "react-router-dom"
import { handlePageChange } from "./Navigation"

export const Footer = () => {

    return (
        <>
            <div className="container py-3">
                <div className="row align-items-center">
                    <div className="col-lg-4">
                        <p className="text-white fs-16 text-lg-left text-center mt-lg-0 mt-3">© {new Date().getFullYear()} Darpeso. <Link to={ROUTES.ABOUT} onClick={handlePageChange}>
                            Privacy Policy
                        </Link></p>
                    </div>
                    <div className="col-lg-8 text-right my-lg-0 my-3">
                        <div className="d-flex align-items-center justify-content-lg-end justify-content-center  flex-wrap ">
                            <a className="theme-btn btn-outline white circular-social-icon   ml-2" target='_blank'>
                                <i className="fa-brands fa-facebook-f"></i>
                            </a>
                            <a className="theme-btn btn-outline white circular-social-icon   ml-2" target='_blank'>
                                <i className="fa-brands fa-instagram"></i>
                            </a>
                            <a className="theme-btn btn-outline white circular-social-icon   ml-2" target='_blank'>
                                <i className="fa-brands fa-x-twitter"></i>
                            </a>
                            <a className="theme-btn btn-outline white circular-social-icon   ml-2" target='_blank'>
                                <i className="fa-brands fa-tiktok"></i>
                            </a>

                            <a className="theme-btn btn-outline white circular-social-icon   ml-2" target='_blank'>
                                <i className="fa-brands fa-linkedin-in"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}