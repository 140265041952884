import React from 'react';
import './App.css';
import { Navigation } from './components';

import { AnimatedRoutes } from './components/Routes/AnimatedRoutes';
import { Footer } from './components/Footer';
import { store } from './store/store';
import { Provider } from 'react-redux';

function App() {

  return (
    <Provider store={store}>
    <div className='dark'>
      <Navigation />
    
    <AnimatedRoutes />
    
    <Footer/>

    </div>
    </Provider>
  );
}

export default App;
