import { ROUTES } from "@/config/types";
import { setNavigation } from "@/store/slices/appSlice";
import { store } from "@/store/store";
import { useEffect, useState } from "react"
import logo from '../assets/images/l00.png';

export const handlePageChange = () => {
    window.scroll({
        top: 0,
        behavior: "smooth",
    });
}

export const Navigation = () => {

    const [toggleNavbar, setToggleNavbar] = useState(false)

    const [isBurgerOpen, setIsBurgerOpen] = useState(false)
    const toggleNavbarHandler = () => {
        const scrolled = window.scrollY

        if (scrolled > 60) {
            setToggleNavbar(true);
        } else if (scrolled <= 60) {
            setToggleNavbar(false);
        }
    };



    // useEffect(() => {
    //     document.querySelector("body").addEventListener('scroll', toggleNavbarHandler)
    // }, [])

    useEffect(() => {
        window.addEventListener('scroll', toggleNavbarHandler, true);

        // Remove the event listener
        return () => {
            window.removeEventListener('scroll', toggleNavbarHandler, true);
        };
    }, []);



    return (
        <>
            <div className={`mobile-menu ${isBurgerOpen ? ' open ' : ''}`} >
                <div className="menu-wrapper  flex-column d-flex h-100 align-items-start align-items-sm-center justify-content-start justify-content-sm-center">
                    <div className="text-white fw-bold fs-30 my-3 pointer " onClick={(e) => {
                        store.dispatch(setNavigation(ROUTES.HOME))
                        setIsBurgerOpen(false)
                    }}>Home</div>

                    <div className="text-white fw-bold fs-30 my-3 pointer" onClick={(e) => {
                        store.dispatch(setNavigation(ROUTES.ABOUT))
                        setIsBurgerOpen(false)
                    }}>About</div>

                    <div className="text-white fw-bold fs-30 my-3 " >Services
                        <div className="pl-3 pt-3">
                            <div className="text-white fw-bold fs-30 py-3 pointer" onClick={(e) => {
                                store.dispatch(setNavigation(ROUTES.CUSTOM_SOFTWARE_DESIGN))
                                setIsBurgerOpen(false)
                            }}>Custom Software Design</div>

                            <div className="text-white fw-bold fs-30 py-3 pointer" onClick={(e) => {
                                store.dispatch(setNavigation(ROUTES.MOBILE_APP_DEVELOPMENT))
                                setIsBurgerOpen(false)
                            }}>Mobile App Development</div>

                            <div className="text-white fw-bold fs-30 py-3 pointer" onClick={(e) => {
                                store.dispatch(setNavigation(ROUTES.WEB_DEVELOPMENT))
                                setIsBurgerOpen(false)
                            }}>Web Development</div>

                            <div className="text-white fw-bold fs-30 py-3 pointer" onClick={(e) => {
                                store.dispatch(setNavigation(ROUTES.ECOMMERCE_SOLUTIONS))
                                setIsBurgerOpen(false)
                            }}>Ecommerce Solutions</div>

                            <div className="text-white fw-bold fs-30 py-3 pointer" onClick={(e) => {
                                store.dispatch(setNavigation(ROUTES.USER_EXPERIENCE_DESIGN))
                                setIsBurgerOpen(false)
                            }}>User Experience Design</div>
                        </div>
                    </div>

                    <div className="text-white fw-bold fs-30 my-3 pointer" onClick={(e) => {
                        store.dispatch(setNavigation(ROUTES.CONTACT))
                        setIsBurgerOpen(false)
                    }}>Contact</div>



                </div>
            </div>
            <div className={` py-3 top-navigation ${toggleNavbar ? ' scrolled ' : ''}`}>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-3 col-6">
                            <img src={logo} alt="" className="logo" />
                        </div>
                        <div className="col-lg-6 d-none d-lg-block">
                            <div className="navigation-items">
                                <div className="navigation-item pointer" onClick={(e) => {
                                    store.dispatch(setNavigation(ROUTES.HOME))
                                }}>
                                    <div className="text-white nav-label ">Home</div>
                                    <div className="line bg-white"></div>
                                </div>

                                <div onClick={(e) => {
                                    store.dispatch(setNavigation(ROUTES.ABOUT))
                                }} className="navigation-item pointer">
                                    <div className="text-white nav-label ">About</div>
                                    <div className="line bg-white"></div>
                                </div>


                                <div className="navigation-item pointer dropdown-wrapper" >
                                    <div className="text-white nav-label ">Services</div>
                                    <div className="line bg-white"></div>
                                    <div className="dropdown-container">
                                        <div className="navigation-item pointer" onClick={(e) => {
                                            store.dispatch(setNavigation(ROUTES.CUSTOM_SOFTWARE_DESIGN))
                                        }}>
                                            <div className="text-white nav-label ">Custom Software Design</div>
                                            <div className="line bg-white"></div>
                                        </div>
                                        <div className="navigation-item pointer" onClick={(e) => {
                                            store.dispatch(setNavigation(ROUTES.MOBILE_APP_DEVELOPMENT))
                                        }}>
                                            <div className="text-white nav-label ">Mobile App Development</div>
                                            <div className="line bg-white"></div>
                                        </div>
                                        <div className="navigation-item pointer" onClick={(e) => {
                                            store.dispatch(setNavigation(ROUTES.WEB_DEVELOPMENT))
                                        }}>
                                            <div className="text-white nav-label ">Web Development</div>
                                            <div className="line bg-white"></div>
                                        </div>
                                        <div className="navigation-item pointer" onClick={(e) => {
                                            store.dispatch(setNavigation(ROUTES.ECOMMERCE_SOLUTIONS))
                                        }}>
                                            <div className="text-white nav-label ">Ecommerce Solutions</div>
                                            <div className="line bg-white"></div>
                                        </div>
                                        <div className="navigation-item pointer" onClick={(e) => {
                                            store.dispatch(setNavigation(ROUTES.USER_EXPERIENCE_DESIGN))
                                        }}>
                                            <div className="text-white nav-label ">User Experience Design</div>
                                            <div className="line bg-white"></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="navigation-item pointer" onClick={(e) => {
                                    store.dispatch(setNavigation(ROUTES.CONTACT))
                                }}>
                                    <div className="text-white nav-label ">Contact</div>
                                    <div className="line bg-white"></div>
                                </div>

                            </div>
                        </div>

                        <div className="col-lg-4 col-6 text-right  d-block d-lg-none">
                            <div className={`burger-menu ml-auto ${isBurgerOpen ? ' open ' : ''}`} onClick={() => {
                                setIsBurgerOpen(p => !p)
                            }}>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}