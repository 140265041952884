import i18n from "i18next";
import {  initReactI18next } from "react-i18next";
import en from '../i18n/en.json'

import LanguageDetector from 'i18next-browser-languagedetector';



// don't want to use this?
// have a look at the Quick start guide 
// for passing in lng and translations on init

i18n.use(LanguageDetector).use(initReactI18next).init({
    fallbackLng: 'en',
    debug: true,
    resources: {
        en: {...en}
      },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    }
  });


export default i18n;