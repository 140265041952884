export const HorizontalWordLoop = ({ title }) => {
    return <div className="horizontal-scroll-loop-container py-lg-5">
        <div className="d-flex position-relative py-5">
            <div data-first className="box d-flex" >
                <h1 className='text-white px-4 '>{title}</h1>
                <h1 className=' stroke px-4 '>{title}</h1>
                <h1 className='text-white px-4 '>{title}</h1>
                <h1 className=' stroke px-4 '>{title}</h1>
                <h1 className='text-white px-4 '>{title}</h1>
                <h1 className=' stroke px-4 '>{title}</h1>
                <h1 className='text-white px-4 '>{title}</h1>
                <h1 className=' stroke px-4 '>{title}</h1>
            </div>
        </div>
    </div>
}