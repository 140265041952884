import { Banner, CTA, HorizontalWordLoop } from '@/components'
import mailer from '../../assets/images/services/gorilla.png'
import animation from '../../assets/animations/Cart.json'
import rates from '../../assets/animations/ecommerce/rates.json'
import conversion from '../../assets/animations/ecommerce/conversion.json'
import store from '../../assets/animations/ecommerce/store.json'
import readyIcon from '../../assets/animations/ecommerce/ready.json'
import Lottie from 'react-lottie';
import { Fade, JackInTheBox } from "react-awesome-reveal";

const defaultOptions = {
    loop: true,
    autoplay: true,

    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};

export const EcommerceDevelopment = () => {

    const RowBlock = ({ pos = 'left', icon, title, description = undefined }) => {
        return <div className={`${pos == 'left' ? 'bg-dark1 ' : ''} py-5`}>
            <div className="container">
                <div className={`row align-items-center justify-content-center ${pos != 'left' ? 'flex-row-reverse' : ''}`}>
                    <div className="col-lg-2 text-center col-md-4 col-4">
                     <Fade  triggerOnce={true}>
                     <Lottie options={{ ...defaultOptions, animationData: icon }}
                            height={'100%'}
                            width={'100%'}
                        />
                     </Fade>
                    </div>
                    
                    <div className="col-lg-auto col-md-8 mt-lg-0 mt-5 text-center text-sm-left">
                        <Fade  triggerOnce={true}>
                        <h3 className='text-primary' dangerouslySetInnerHTML={{ __html: title }} />
                        <>{description}</>
                        </Fade>
                    </div>
                </div>
            </div>
        </div>
    }
    return <>
        <JackInTheBox triggerOnce={true} delay={1000}>
            <Banner
                subtitle={"Business Development"}
                title={' <span class="stroke">ECOMMERCE </span> <br />Solutions'}
                image={mailer}
                description="Start your e-commerce business with a fully operational store ready to generate revenue."
            />
        </JackInTheBox>
        <Fade triggerOnce={true} delay={100}>
            <HorizontalWordLoop title={"ECOMMERCE SOLUTIONS"} />
        </Fade>
        <div className={` bg-dark1 '  py-5`}>
            <div className="container">
                <div className={`row align-items-center justify-content-center justify-content-lg-start `}>
                    <div className="col-lg-2 col-md-4 text-center col-8">
                        <Lottie options={{ ...defaultOptions, animationData: store }}
                            height={'100%'}
                            width={'100%'}
                        />
                    </div>
                    <div className="col-lg-10 mt-lg-0 mt-3 ">
                        <h3 className='text-primary' >
                            What makes a <span className="stroke primary">HIGH QUALITY</span>e-commerce site essential for success?
                        </h3>
                        <ul className='list mt-3'>
                            <li><p className='text-white fs-16 '>To scale the business globally and capture a bigger market share</p></li>
                            <li><p className='text-white fs-16 '>To save both time and money by optimizing operational processes and eliminating inefficiencies</p></li>
                            <li><p className='text-white fs-16 '>To simplify buying process, reach a wider audience and generate more revenue</p></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>


        <RowBlock
            pos='right'
            icon={readyIcon}
            title={'Build A <span class="stroke primary">READY-TO-GO</span><br>Digital Commerce Store'}
        />
        <RowBlock
            icon={animation}
            title={'Drive More <span class="stroke primary">ORGANIC</span><br>Traffic And Sales'}
        />

        <RowBlock
            pos='right'
            icon={rates}
            title={'Ensure an <span class="stroke primary">EXCELLENT</span><br> User Experience'}
        />


        <RowBlock
            icon={conversion}
            title={'Get <span class="stroke primary">HIGHER</span><br>Conversion Rates'}
        />
        {/*   
  <RowBlock
            pos='right'
            icon={rates}
            title={'Provide A Great<br><span class="stroke primary">CUSTOMER</span> JOURNEY'}
        /> */}
        <CTA
            title={"Bridging <span class='stroke'>Ideas</span><br>To <span class='stroke '>Solutions</span>"}
            description={"Are you ready to start a new software development project? Work with our team of software designers and developers. It’s never too early, or too late, to bring your idea to life."} />

    </>

}

// Build a ready-to-go digital commerce store
// We are building websites from scratch combining ready-made and custom solutions to deliver outstanding e-commerce website design and development. Get the best time-to-market and full compliance with your requirements .


// Drive more organic traffic and sales
// A fast-loading e-commerce website with quality content increases organic traffic and gives you a stream of targeted visitors. You get a fully optimized e-commerce website that takes top positions of search engine results and boosts both your brand awareness and sales.

// Ensure an excellent user experience
// Provide flexible, personalized website search and navigation in your turn-key digital store to ensure a delightful customer experience. We will support you at every step of e-commerce website development: from choosing the optimal search engine to integrating your store with third-party services according to your business goals.


// Get higher conversion rates
// A key goal of any digital e-commerce store is conversion. Get a well-optimized website, ready to deliver a seamless UX/UI with a smooth login and clear checkout to guide your buyers to completed deals. We combine best e-commerce website development practices and our company expertise to help you turn traffic into sales for stable revenue growth.


// Provide a great customer journey
// One of the major and the most expensive goals of the e-commerce website is to engage prospects and turn them into loyal customers. Get a digital store equipped with robust tools to keep shoppers satisfied throughout the customer journey. Providing e-commerce website development service as an agency we are focused on building a successful customer journey strategy with attention across all touchpoints to maximize the retention rate.


// Communication
// The basis for the success of any project lies in quality communication between the business owner and the development team. We focus not only on timely answers but also on listening to your business goals and helping you achieve them. From e-commerce website creation to post-release activities, a dedicated Amasty manager is here to support, consult, and advise you on how you could accelerate your business growth.