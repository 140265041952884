import { Banner, CardItem, HorizontalWordLoop } from '@/components'
import mailer from '../assets/images/tiger.png'
import { CTA } from '@/components/CTA'
import { Fade, Flip, Zoom, Roll, JackInTheBox, Slide } from "react-awesome-reveal";
import { windowWidth } from '@/services/services';

export const About = () => {
    return (
        <>
            <JackInTheBox triggerOnce={true} delay={1000}>
                <Banner
                    subtitle={"WHO WE ARE ?"}
                    title={'<span class="stroke ">YOUR </span> CHALLENGES <br /> OUR <span class="stroke">SOLUTIONS</span>'}
                    image={mailer}
                    description="At the forefront of innovation, we create cutting-edge software solutions that simplify processes and drive your business forward."
                />
            </JackInTheBox>
            <div className="   container pt-lg-5 ">
                <div className="row justify-content-center pt-5">
                    <div className="col-lg-3 mt-2">
                        <Fade triggerOnce={true} delay={200}><h6 className="sub-title text-white">Our Story.</h6></Fade>
                    </div>
                    <div className="col-lg-7">
                        <Fade triggerOnce={true} delay={400}>  <h3 className='text-white'>IT STARTED WITH A <span className="stroke">VISION</span></h3></Fade>
                        <Fade triggerOnce={true} delay={600}><p className="text-white mt-2 fs-30">
                            <span className=""><b>DARPER</b></span> in Armenian means <i>Different</i>. <br /><span className=""><b>SO</b></span> is short for <i>Solutions</i> combined together it means Different Solutions. <br />Darperso excels in providing custom software solutions for businesses seeking to improve their business by merging it in technological world.
                        </p></Fade>
                        <Fade triggerOnce={true} delay={800}><p className="mt-1">
                            <span className=" text-primary fs-30"><b>Your challenges can be solved by our solutions !</b></span>
                        </p></Fade>
                    </div>
                </div>

            </div>
            <Fade triggerOnce={true} delay={100}>
                <HorizontalWordLoop title={"ABOUT US"} />
            </Fade>
            <div className="container pb-5">
                <div className="row">
                    <div className="col-lg-12">
                        <Slide delay={0}><h1 className='text-white pb-4'>Core Values</h1></Slide>
                    </div>
                    <div className="col-lg-4 col-md-6 pt-3 pb-2">
                        <CardItem
                            delay={1000}
                            icon={"fa-solid fa-rocket"}
                            title={"Innovation"}
                            text={"We are ambitious and always seeking opportunities to iterate on great ideas. Experience and expertise drive us to try new things, embrace change, and trust in the guidance of our leadership and teammates to lead us to worthy challenges."}
                        />
                    </div>

                    <div className="col-lg-4 col-md-6 pt-3 pb-2">
                        <CardItem
                            delay={1200}
                            icon={"fa-regular fa-lightbulb"}
                            title={"Accountability"}
                            text={"We are ambitious and always seeking opportunities to iterate on great ideas. Experience and expertise drive us to try new things, embrace change, and trust in the guidance of our leadership and teammates to lead us to worthy challenges."}
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 pt-3 pb-2">
                        <CardItem
                            delay={windowWidth <= 1 ? 1000 : 1400}
                            icon={"fa-solid fa-chart-line"}
                            title={"Challenge"}
                            text={"We encourage our team members to push the boundaries of “what we’ve always done” because we can’t get to the next phase without thinking creatively. With challenging perspectives comes a deep level of required respect and empathy, recognizing that a differing path has its reasoning and validation."}
                        />
                    </div>

                    <div className="col-lg-4 col-md-6 pt-3 pb-2">
                        <CardItem
                            delay={windowWidth <= 1 ? 1000 : 1600}
                            icon={"fa-regular fa-handshake"}
                            title={"Trust"}
                            text={"We are a team of leaders with incredible knowledge to share amongst our team. We encourage independent thought, but also seeking feedback and help from others when necessary. We’re on a mission to succeed collectively, which means we need each other to get there."}
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 pt-3 pb-2">
                        <CardItem
                            delay={windowWidth <= 1 ? 1000 : 1800}
                            icon={"fa-solid fa-people-group"}
                            title={"Collaboration"}
                            text={"Different perspectives lead to innovative ideas, as well as team bonding, both of which we believe make a great team and lead to finding the best solution."}
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 pt-3 pb-2">
                        <CardItem
                            delay={windowWidth <= 1 ? 1200 : 2000}
                            icon={"fa-regular fa-comments"}
                            title={"Communication"}
                            text={"We are an open team, which means vocalizing our needs, our challenges, and our passions publicly and often. As an organization, we strive to be transparent in the direction we take and explain the reasons why, when possible."}
                        />
                    </div>

                </div>

            </div>

            <CTA
                title={"Let's Bring <br /><span class='stroke '>Your</span> Ideas To <span class='stroke '>Life</span>"}
                description={"Are you ready to turn your vision into a reality? <br> Whether you’re just getting started or looking to level up an existing idea, now is the perfect time to make it happen. Let’s build something great together!"} />
        </>
    )
}