import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';

import "./assets/css/main.css";
import "./assets/fonts/sora/stylesheet.css";
import "./assets/fonts/epilogue/stylesheet.css";
import "./assets/css/fontawesome.min.css";
import "./assets/fontawesome/css/all.min.css";
import { BrowserRouter } from "react-router-dom";

import './services/languageServices'


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <BrowserRouter>
    {/* <Provider store={store}> */}
    {/* <HelmetProvider> */}
    <App />
    {/* </HelmetProvider> */}

    {/* </Provider> */}
  </BrowserRouter>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
