import { configureStore } from "@reduxjs/toolkit"
import appReducer from "./slices/appSlice"

const reducer = {
    app: appReducer,
   
}

export const store = configureStore({
    reducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
})

export type RootState = ReturnType<typeof store.getState>
