import { Fade, Slide } from "react-awesome-reveal"

export const Banner = ({ image, title, description, subtitle }) => {
    return <div className=" banner  " style={{ backgroundImage: `url(${image})` }}>
        <div className="overlay black"></div>
        <div className="container d-flex mh-100v  py-100 align-items-center ">
            <div className="row  ">
                <div className="col-lg-12   ">
                    <div className="caption position-relative white-always">
                        <Fade triggerOnce={true} delay={1500}><h6 className='sub-title pl-3 text-white'>{subtitle}</h6></Fade>
                    </div>
                </div>
                <div className="col-lg-12  mt-2 ">
                    <Fade triggerOnce={true} delay={1800}>
                        <h1 className='text-white-always' dangerouslySetInnerHTML={{ __html: title }} />
                    </Fade>
                </div>
                <div className="col-lg-6">
                    <Fade triggerOnce={true} delay={2000}>
                        <p className='text-white-always mt-3 pl-lg-5 pl-md-3 pl-0'>{description}</p>
                    </Fade>

                </div>
            </div>
        </div>
    </div>
}