import { Banner, CTA, HorizontalWordLoop } from '@/components'
import mailer from '../../assets/images/services/birdPhone.png'
import animation from '../../assets/animations/MobileDevelopment.json'
import Lottie from 'react-lottie';
import { Fade, JackInTheBox } from "react-awesome-reveal";

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animation,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};

export const MobileAppDevelopment = () => {

    return <>
        <JackInTheBox triggerOnce={true} delay={1000}>
            <Banner
                subtitle={"Software Development"}
                title={' <span class="stroke">INNOVATIVE </span> <br />Mobile App Development'}
                image={mailer}
                description=" Empower your business with cutting-edge mobile app development solutions that drive real transformation.
                        Let’s partner to build an app that sets you apart and keeps you ahead of the curve."
            />
        </JackInTheBox>


        <Fade triggerOnce={true} delay={100}>
            <HorizontalWordLoop title={"APP DEVELOPMENT"} />
        </Fade>
        <div className="bg-dark1">
            <div className="container">
                <div className="row py-5 align-items-center justify-content-center justify-content-lg-start ">
                <div className="col-lg-4 col-8">
                        <Fade triggerOnce={true}>
                            <Lottie options={defaultOptions}
                                height={'100%'}
                                width={'100%'}
                            />
                        </Fade>
                    </div>
                    <div className="col-lg-8 mt-lg-0 mt-3">
                        <Fade triggerOnce={true}>
                            <h3 className='text-primary'>We <span className="stroke primary">Build</span>  Mobile Apps Engineered to <span className="stroke primary">Perform</span></h3>
                            <p className="text-white mt-3 ">
                                From crafting a mobile strategy to launching your app on major app stores, we streamline the entire process to bring mobility to your organization.
                                Our goal is to empower innovation and tackle business challenges by leveraging the potential of mobile technology.
                            </p>
                            <p className="text-white mt-3 ">
                                Our philosophy is that technology should adapt to the app’s needs, not the other way around. That’s why we offer versatile development solutions, from responsive web applications to native mobile apps built for app store success.
                                Our philosophy is that technology should adapt to the app’s needs, not the other way around. That’s why we offer versatile development solutions, from responsive web applications to native mobile apps built for app store success. As a trusted mobile app solution provider, we collaborate with you to deliver a mobile experience that supports your vision and engages your audience.As a trusted mobile app solution provider, we collaborate with you to deliver a mobile experience that supports your vision and engages your audience.
                            </p>
                        </Fade>
                    </div>
                </div>
            </div>
        </div>
        <CTA
            title={"<span class='stroke'>Create</span> The Next<br /><span class='stroke '>Big</span>  &nbsp;&nbsp;Thing"}
            description={"It’s never too early or too late to bring your idea to life."} />

    </>

}