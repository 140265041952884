import { ROUTES } from "@/config/types";
import { createSlice } from "@reduxjs/toolkit"

interface Interface {
    isLoading?:boolean;
    navigation?:ROUTES
}

const initialState: Interface = {
    isLoading: true,
    navigation: undefined,
}

const appSlice = createSlice({
    name: "app",
    initialState,
    reducers: {
        setIsLoading (state, action) {
            state.isLoading = action.payload
        },
        setNavigation (state, action) {
            state.navigation = action.payload
        },
    },
})

export const { setIsLoading,setNavigation} = appSlice.actions
export default appSlice.reducer
