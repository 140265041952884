import { Link, useNavigate } from 'react-router-dom'
import cheetahImage from '../assets/images/cheetah.png'
import { ROUTES } from '@/config/types'
import { CardItem, CTA, handlePageChange, ProcessItem } from '@/components'
import animationMobileDevelopment from '../assets/animations/MobileDevelopment.json'
import rates from '../assets/animations/ecommerce/rates.json'
import { Fade, Flip, Zoom, Roll, JackInTheBox, Slide } from "react-awesome-reveal";
import astronaut from '../assets/animations/astronaut.json'
import WebDevelopment from '../assets/animations/MobileBrowser.json'
import cart from '../assets/animations/Cart.json'
import { TypeAnimation } from 'react-type-animation'
import { windowWidth } from '@/services/services'
import { store } from '@/store/store'
import { setNavigation } from '@/store/slices/appSlice'


export const Home = () => {
    const navigate = useNavigate()
    return (
        <>
            <JackInTheBox triggerOnce={true} >
                <div className=" banner " style={{ backgroundImage: `url(${cheetahImage})` }}>
                    <div className="overlay black"></div>
                    <div className="container py-100 d-flex mh-100v align-items-center justify-content-center">
                        <div className="row  ">
                            <div className="col-lg-12">
                                <Fade triggerOnce={true} delay={1500}>
                                    <h1 className='text-white-always'>
                                        HIGH END <br /><span className="stroke ">CREATIVE</span> SOLUTIONS
                                    </h1>
                                </Fade>
                            </div>
                            <div className="col-lg-4">
                                <div className="pl-lg-5 mt-3">
                                    <Fade triggerOnce={true} delay={1800}>
                                        <p className='text-white-always '>Darperso is a software company centered around helping customers find technological solutions for their business.</p>
                                    </Fade>
                                    <div className="d-flex mt-3">
                                        <Fade triggerOnce={true} delay={2000}>
                                            <a className=" theme-btn btn-outline white mr-3" href="#services">
                                                <span>Services</span>
                                            </a>
                                            <div className=" theme-btn btn-filled pointer " onClick={() => {
                                                store.dispatch(setNavigation(ROUTES.CONTACT))

                                            }}>
                                                <span>Free Consultation</span>
                                            </div>
                                        </Fade>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </JackInTheBox>
            <div className="container py-100 our-services" id="services">
                <div className="row justify-content-center">
                    <div className="col-lg-12">
                        <Slide delay={200} > <h1 className='text-white pb-4'>OUR <span className="stroke">SOLUTIONS</span></h1></Slide>
                    </div>
                    <div className="col-lg-4 col-md-6 pt-3 pb-2">
                        <CardItem
                            delay={1000}
                            onClick={() => {
                                store.dispatch(setNavigation(ROUTES.WEB_DEVELOPMENT))
                            }}
                            lottie={WebDevelopment}
                            title={"Web Development"}
                            text={"Build innovative web application that powers your business through custom web development.."}
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 pt-3 pb-2">
                        <CardItem
                            delay={1200}
                            onClick={() => {
                                store.dispatch(setNavigation(ROUTES.CUSTOM_SOFTWARE_DESIGN))
                            }}
                            icon={"fa-solid fa-rocket"}
                            lottie={astronaut}
                            title={"Custom Software Design"}
                            text={"Translate your vision and business requirements into an actionable software design."}
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 pt-3 pb-2">
                        <CardItem
                            delay={windowWidth <= 1 ? 1000 : 1400}
                            lottie={animationMobileDevelopment}
                            onClick={() => {
                                store.dispatch(setNavigation(ROUTES.MOBILE_APP_DEVELOPMENT))
                            }}
                            title={"Mobile App Development"}
                            text={"Solve business problems with mobile technology. Build a consumer or enterprise app."}
                        />
                    </div>

                    <div className="col-lg-4 col-md-6 pt-3 pb-2">
                        <CardItem
                            delay={windowWidth <= 1 ? 1200 : 1600}
                            lottie={cart}
                            onClick={() => {
                                store.dispatch(setNavigation(ROUTES.ECOMMERCE_SOLUTIONS))
                            }}
                            title={"Ecommerce Solutions"}
                            text={"Start your e-commerce business with a fully operational store ready to generate revenue."}
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 pt-3 pb-2">
                        <CardItem
                            delay={windowWidth <= 1 ? 1000 : 1800}
                            lottie={rates}
                            onClick={() => {
                                store.dispatch(setNavigation(ROUTES.USER_EXPERIENCE_DESIGN))
                            }}
                            title={"User Experience Design"}
                            text={"Support your customer journey through the design of a seamless user experience."}
                        />
                    </div>
                </div>
            </div>

            <Zoom triggerOnce={true} delay={500}>
                <div className="bg-primary py-100 text-center px-3  hover-khosher">
                    <div className="row justify-content-center">
                        <div className="col-lg-8">
                            <h2 style={{ opacity: 0, }}>WE HELP BUSINESSES AND STARTUPS MEET THEIR INNOVATIVE GOALS</h2>
                            <div className="position-absolute" style={{ top: 0 }}>
                                <Fade delay={800}>
                                    <TypeAnimation
                                        sequence={[
                                            'WE HELP BUSINESSES AND STARTUPS MEET THEIR INNOVATIVE GOALS',
                                            1000,
                                            ''
                                        ]}
                                        omitDeletionAnimation={true}
                                        wrapper="h2"
                                        className='text-white'
                                        speed={50}
                                        repeat={Infinity}
                                    />
                                </Fade>
                            </div>
                        </div>
                    </div>
                </div>
            </Zoom>
            <div className="container py-100 our-services" id="services">
                <div className="row justify-content-center">
                    <div className="col-lg-12">
                        <Slide delay={200} direction='left'>
                            <h1 className='text-white '>HOW WE DEVELOP</h1>
                        </Slide>
                        <Slide direction='left' delay={400}>
                            <h1 className='text-white pb-4'>A <span className="stroke">PERFECT</span> SOLUTION</h1>
                        </Slide>
                    </div>

                    <div className="col-xl-4 px-3 px-md-1 col-md-6 col-12 py-0 py-3">
                        <ProcessItem
                            title={"Identifying Your Needs"}
                            count={"Step 01"}
                            delay={1000}
                            pos='bottom'
                            content={"In our initial consultations, we seek to understand your company’s background, vision, and product idea. Through a detailed assessment of your needs, we outline the project’s scope, main functionalities, goals, and strategies for success."}
                        />
                    </div>
                    <div className="col-xl-4 px-3 px-md-1 col-md-6 col-12 py-0 py-3">
                        <ProcessItem
                            title={"Plan & Design"}
                            delay={1200}

                            count={"Step 02"}
                            content={"The design aspect involves developing wireframes, user interfaces, and architecture that meet user experience and technical standards, creating a unified vision that reduces risks and supports efficient development."}
                        />
                    </div>
                    <div className="col-xl-4 px-3 px-md-1 col-md-6 col-12 py-0 py-3">
                        <ProcessItem
                            title={"Development and Integration"}
                            count={"Step 03"}
                            pos='bottom'
                            delay={windowWidth <= 1 ? 1000 : 1400}
                            content={"We bring your ideas to life by building and integrating all system components, maintaining open communication throughout. Client feedback is implemented promptly, ensuring alignment with vision and needs at every step."}
                        />
                    </div>
                    <div className="col-xl-4 px-3 px-md-1 col-md-6 col-12 py-0 py-3">
                        <ProcessItem
                            title={"Training & Maintenance "}
                            count={"Step 05"}
                            delay={windowWidth <= 1 ? 1200 : 1600}

                            content={"After project implementation, we offer training and provide ongoing support for maintenance and inquiries. We're also available to expand or modify the project as needed."}
                        />
                    </div>
                    <div className="col-xl-4 px-3 px-md-1 col-md-6 col-12 py-0 py-3">
                        <ProcessItem
                            delay={windowWidth <= 1 ? 1000 : 1800}

                            title={"Testing and Quality Assurance:"}
                            count={"Step 04"}
                            content={"We perform comprehensive testing, including unit, quality, and user acceptance tests, to ensure our solution is functional, user-friendly, secure, and reliable"}
                        />
                    </div>
                </div>
            </div>
            <CTA
                title={"Let's Bring <br /><span class='stroke '>Your</span> Ideas To <span class='stroke '>Life</span>"}
                description={"Are you ready to turn your vision into a reality? <br> Whether you’re just getting started or looking to level up an existing idea, now is the perfect time to make it happen. Let’s build something great together!"} />

            <div className="bg-primary py-100 text-center px-3 hover-khosher">
                <div className="row justify-content-center">
                    <div className="col-lg-8">
                        <h2 className='text-white mb-3'>Why Choose DarperSo ? </h2>
                        <div className="position-relative">
                            <h2 style={{ opacity: 0 }}> Anticipate beyond what is guaranteed.</h2>
                            <div className="position-absolute center w-100" style={{ top: 0 }}>
                                <TypeAnimation
                                    sequence={[
                                        "Price Aren't Overboard",
                                        700,
                                        "Your Success is our Success",
                                        700,
                                        "We Deliver on Time",
                                        700,
                                        "Anticipate beyond what is guaranteed.",
                                        700,
                                        "We're Always Improving",
                                        700,
                                    ]}
                                    omitDeletionAnimation={true}
                                    wrapper="h3"
                                    className='text-white '
                                    speed={25}
                                    repeat={Infinity}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

