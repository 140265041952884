import { Rotate } from "react-awesome-reveal"

export const ProcessItem = ({count, title,content, pos='top' ,delay=0}) => {

    return <Rotate  delay={delay}>
      <div className={`process-item ${pos}`}>
      <div className="p-3">
      <div className='count text-center text-primary'>{count}</div>
        <div className="title text-center text-white py-3">{title}</div>
        <div className="line mb-3"></div>
        <div className="content text-center text-white">{content}</div>
      </div>
    </div>
    </Rotate>
}