import { Accordion, Banner, CTA, HorizontalWordLoop } from '@/components'
import mailer from '../../assets/images/services/groupCustom.png'
import animation from '../../assets/animations/astronaut.json'
import Lottie from 'react-lottie';
import { Fade, Flip, Zoom, Roll, JackInTheBox, Slide } from "react-awesome-reveal";

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animation,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};

export const CustomSoftwareDesign = () => {

    return <>
        <JackInTheBox triggerOnce={true} delay={1000}>
            <Banner
                subtitle={"Software Development"}
                title={' <span class="stroke">CUSTOM </span> SOFTWARE  <br />DESIGN SOLUTIONS'}
                image={mailer}
                description="Create your software design blueprint with expert software design services."
            />
        </JackInTheBox>
        <Fade triggerOnce={true} delay={100}>
            <HorizontalWordLoop title={"CUSTOM SOFTWARE DESIGN"} />
        </Fade>
        <div className="bg-dark1">
            <div className="container">
                <div className="row py-5 align-items-center justify-content-center justify-content-lg-start ">
                    <div className="col-lg-4 col-8">
                        <Fade triggerOnce={true}>
                            <Lottie options={defaultOptions}
                                height={'100%'}
                                width={'100%'}
                            />
                        </Fade>
                    </div>
                    <div className="col-lg-8 mt-lg-0 mt-3">
                        <Fade triggerOnce={true}>
                            <h3 className='text-primary'>Successful applications aren’t just coded, they are engineered.</h3>
                        </Fade>
                        <Fade triggerOnce={true}>

                            <p className="text-white mt-3 ">
                                Delivering software projects that deliver outstanding user experiences is a team effort for us. We recognize that custom software design and development must be collaborative and cooperative to be fruitful.
                            </p>
                            <p className="text-white mt-3 ">
                                From solution architects to business analysts, quality assurance analysts, and yes, software engineers, we work together to deliver each software project. We engage in thoughtful planning and collaboration with our customers to build a detailed software architecture and software solutions design.
                            </p>
                            <p className="text-white mt-3 ">
                                With this strategic approach to custom software design, we develop and deliver software projects that help our customers grow their businesses today and in the future.
                            </p>
                        </Fade>

                    </div>
                </div>
            </div>
        </div>

        <div className="container py-5">
            <div className="row">
                <div className="col-12">
                    <Slide delay={200} ><h4 className='text-primary  pb-2'>Things You Should Know About</h4></Slide>
                    <Slide delay={400} ><h2 className='text-primary pt-1'>Custom Software Design</h2></Slide>
                </div>

                <div className="col-12 mt-3">
                    <Fade triggerOnce={true}>
                        <Accordion
                            delay={1000}
                            title={"How much does custom software cost?"}
                            children={"<p class='text-white pb-3'>Costs vary by size and scope of the project. Click to read the different variables that can affect your project.</p>"}
                        />

                        <Accordion
                            delay={1200}
                            title={"How long will my project take?"}
                            children={"<p class='text-white pb-3'>No two projects are alike or take the same amount of time to build. The size and complexity of the project are the biggest factors in overall duration. Click to read more.</p>"}
                        />

                        <Accordion
                            delay={1400}

                            title={"I have an idea, now what?"}
                            children={"<p class='text-white pb-3'>Having a new software development idea can be exciting. Click to see our 6 Steps On How To Get Started With Your Software Idea.</p>"}
                        />

                        <Accordion
                            delay={1600}
                            title={"What can delay my custom software project?"}
                            children={"<p class='text-white pb-3'>Software projects often take longer than planned. Click for the common reasons why projects run over their initial time estimates.</p>"}
                        />

                        <Accordion
                            delay={1800}
                            title={"How do I turn a troubled project around?"}
                            children={"<p class='text-white pb-3'>There are many ways a software project can go wrong. What you do to turn the project around can make all the difference. Click to read how to get the project back on track.</p>"}
                        />
                    </Fade>
                </div>
            </div>
        </div>
        <CTA
            title={"Bridging <span class='stroke'>Ideas</span><br>To <span class='stroke '>Solutions</span>"}
            description={"Are you ready to start a new software development project? Work with our team of software designers and developers. It’s never too early, or too late, to bring your idea to life."} />


    </>

}

// Things You Should Know About Custom Software Design

// How much does custom software cost?
// Costs vary by size and scope of the project. Click to read the different variables that can affect your project.

// How long will my project take?
// No two projects are alike or take the same amount of time to build. The size and complexity of the project are the biggest factors in overall duration. Click to read more.


// I have an idea, now what?
// Having a new software development idea can be exciting. Click to see our 6 Steps On How To Get Started With Your Software Idea.


// What can delay my custom software project?
// Software projects often take longer than planned. Click for the common reasons why projects run over their initial time estimates.

// How do I turn a troubled project around?
// There are many ways a software project can go wrong. What you do to turn the project around can make all the difference. Click to read how to get the project back on track.