import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation, Navigate, useNavigate } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import { ROUTES } from "@/config/types";
import { About, Contact, CustomSoftwareDesign, EcommerceDevelopment, Home, MobileAppDevelopment, UserExperience, WebDevelopment } from "@/screens";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/store/store";
import { setIsLoading } from "@/store/slices/appSlice";

// import {} from 'framer-motion' /dist/framer-motion
export const AnimatedRoutes = (props: any) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const navigationRoute = useSelector((store: RootState) => store.app.navigation)
  const isLoading = useSelector((store: RootState) => store.app.isLoading)


  useEffect(() => {
    dispatch(setIsLoading(true))
    
    setTimeout(()=>{
      window.scroll({
        top: 0,
      });
    },500)
  if(navigationRoute){
    setTimeout(() => {
      navigate(navigationRoute)
    },2200)
  }
    setTimeout(() => {
      dispatch(setIsLoading(false))
    }, 2500)
    // 
  }, [navigationRoute])
  // const isApiLoading = useSelector((state: RootState) => state.admin.isApiLoading);
  // const apiResponse = useSelector((state: RootState) => state.admin.apiResponse);

  return (
    <>
      <div className={`page-loader ${isLoading ? 'show ': ''}`}></div>
      <AnimatePresence>
        {/* <div className={`api-response-alert ${apiResponse ? ' loaded ' : ''}`}>
        <div className={`alert text-center alert-${apiResponse?.success ? 'success ' : apiResponse==undefined?  ' ' : ' danger '}`} role="alert">
          {apiResponse?.message}
        </div>
      </div> */}
        {/* <div className={`admin-loader-wrapper ${!isApiLoading ? ' loaded ' : ''}`}
        style={{
          height: '100vh',
          width: '100%',
          position: 'fixed',
          top: 0, left: 0,
          background: 'black',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 99999999,
        }}

      >
        <div className="spinner">
          <div className="spinner-item"></div>
          <div className="spinner-item"></div>
          <div className="spinner-item"></div>
          <div className="spinner-item"></div>
          <div className="spinner-item"></div>
        </div>
      </div> */}


        <Routes location={location} key={location.pathname}>
          <Route path="" element={<Home />} />
          <Route path={ROUTES.CONTACT} element={<Contact />} />
          <Route path={ROUTES.ABOUT} element={<About />} />
          <Route path={ROUTES.ABOUT} element={<About />} />
          <Route path={ROUTES.CUSTOM_SOFTWARE_DESIGN} element={<CustomSoftwareDesign />} />
          <Route path={ROUTES.WEB_DEVELOPMENT} element={<WebDevelopment />} />
          <Route path={ROUTES.MOBILE_APP_DEVELOPMENT} element={<MobileAppDevelopment />} />
          <Route path={ROUTES.USER_EXPERIENCE_DESIGN} element={<UserExperience />} />
          <Route path={ROUTES.ECOMMERCE_SOLUTIONS} element={<EcommerceDevelopment />} />

          {/* {isLoggedIn ? ( */}

          <Route path='*' element={<Navigate to={"/"} />} />

        </Routes>
      </AnimatePresence>

    </>
  );
};
