import { Banner, CTA, HorizontalWordLoop } from '@/components'
import mailer from '../../assets/images/services/panda.png'
import animation from '../../assets/animations/ecommerce/design.json'
import Lottie from 'react-lottie';
import { Fade, JackInTheBox } from "react-awesome-reveal";

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animation,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};

export const UserExperience = () => {

    return <>
        <JackInTheBox triggerOnce={true} delay={1000}>
            <Banner
                subtitle={"UI/UX Development"}
                title={' <span class="stroke">USER </span> <br /> EXPERIENCE DESIGN'}
                image={mailer}
                description="Elevate Your Software Experience"
            />
        </JackInTheBox>

        <Fade triggerOnce={true} delay={100}>
            <HorizontalWordLoop title={"USER EXPERIENCE DESIGN"} />
        </Fade>
        
        <div className="container">
            <div className="row pb-5 align-items-center justify-content-center justify-content-lg-start ">
                <div className="col-lg-4 col-8">
                    <Fade triggerOnce={true}>
                        <Lottie options={defaultOptions}
                            height={'100%'}
                            width={'100%'}
                        />
                    </Fade>
                </div>
                <div className="col-lg-8 mt-lg-0 mt-3">
                    <Fade triggerOnce={true}>
                        <h3 className='text-primary'>Merge user thought and software design into a single process.</h3>
                        <p className="text-white mt-3 ">
                            We help create and execute innovative software user experiences through visual design. With our experience and skill in design, we’ll take your project from early proof-of-concept prototyping all the way to complete web and mobile product deliv
                        </p>
                        <p className="text-white mt-3 ">
                            Unlike other firms who work in isolation on your project, we collaborate with your team every step of the way. We want to ensure the software user experience we design meets the needs of your modern business. By partnering with us, you’ll simplify your software UI design process from concept to creation because you’re only dealing with a single vendor.
                        </p>
                    </Fade>
                </div>
            </div>
        </div>
        <CTA
            title={"“It takes 20 years to build a reputation and five minutes to ruin it. If you think about that, you’ll do things differently.”"}
            description={"Warren Buffett"} />

    </>

}